import React, { useState, useEffect } from "react";
import axios from "axios";
import { showError } from "./AlertMessage";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import GetBase from "./GetBase";

export default function Profile() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [technologyName, setTechnologyName] = useState([]);
  const [mobile, setMobile] = useState("");
  const [photo, setPhoto] = useState(null);
  const [technologies, setTechnologies] = useState([]);
  const [addTechnology, setAddTechnology] = useState("");
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const navigate = useNavigate();
  const id = localStorage.getItem("userId");

  useEffect(() => {
    if (!id) {
      navigate("/", { replace: true });
    }
  }, [id, navigate]);

  useEffect(() => {
    axios
      .get(GetBase() + `user-info/${id}`)
      .then((response) => {
        const {
          first_name,
          last_name,
          email,
          password,
          company_name,
          mobile,
          photo,
        } = response.data;
        setFirstName(first_name);
        setLastName(last_name);
        setEmail(email);
        setPassword(password);
        setCompanyName(company_name);
        setMobile(mobile);
        setPhoto(photo);
      })
      .catch((error) => console.error("Error fetching user data:", error));
  }, [id]);

  useEffect(() => {
    axios
      .get(GetBase() + `user-technology/${id}`)
      .then((response) => setTechnologyName(response.data))
      .catch((error) =>
        console.error("Error fetching technology data:", error)
      );
  }, [id]);

  useEffect(() => {
    axios
      .get(GetBase() + "technology")
      .then((response) => setTechnologies(response.data))
      .catch((error) => console.error("Error fetching technologies:", error));
  }, []);

  const getPhotoUrl = () => {
    if (typeof photo === "string") {
      if (photo.startsWith("https://")) return photo; 
      return GetBase() + `uploads/${photo}`; 
    } else if (photo instanceof File) {
     
      return URL.createObjectURL(photo);
    }
    return "../assets/img/avatar.png"; 
  };
  
  
  const handleTechnologySubmit = async (e) => {
    e.preventDefault();
  
    const techExists = technologyName.some(
      (tech) => tech.id === parseInt(addTechnology)
    );
  
    if (techExists) {
      showError("This technology is already added.");
      return;
    }
  
    try {
      const techResponse = await axios.get(GetBase() + `technology/${addTechnology}`);
  
      axios
        .post(GetBase() + "create-payment", {
          order_amount: Number(techResponse.data.price), 
          customer_name: firstName,
          customer_email: email,
          customer_phone: mobile,
          return_url: 'https://synoventum.4born.in/payment-result2',
          // return_url: 'https://localhost:3000/payment-result2',
        })
        .then((response) => {
          if (response.data && response.data.payment_link) {
            const orderId = response.data.order_id;
  
            if (!orderId) throw new Error("Order ID is missing in the response.");
  
            // Store tech ID and other data in local storage
            localStorage.setItem("orderId3", orderId);
            localStorage.setItem("userId", id);
            localStorage.setItem("customerName", firstName);
            localStorage.setItem("customerEmail", email);
            localStorage.setItem("customerPhone", mobile);
            const updatedTechnologies = [...selectedTechnologies, addTechnology];
            setSelectedTechnologies(updatedTechnologies); 
            localStorage.setItem("selectedTechnologies", JSON.stringify(updatedTechnologies));
  
            window.location.href = response.data.payment_link;
          } else {
            console.error("Error: Payment link not found");
            showError("Payment link not found. Please try again.");
          }
        })
        .catch((error) => {
          console.error("Error initiating payment:", error);
          showError("Add your mobile number before adding technologies.");
        });
    } catch (error) {
      showError("Error adding technology. Please try again.");
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    data.append("email", email);
    data.append("password", password);
    data.append("companyName", companyName);
    data.append("mobile", mobile);
    if (photo) data.append("photo", photo); 

    try {
        const response = await axios.put(GetBase() + `user-info/${id}`, data, {
            headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.status === 200) {
            alert("Profile updated successfully!");
            if (photo) setPhoto(photo);
        } else {
            console.error("Unexpected response:", response.data);
        }
    } catch (error) {
        console.error("Error:", error.response?.data || error.message);
        showError("Please try again.");
    }
};

  
  

  return (
    <div className="container profile-box">
      <ToastContainer />
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-12">
          <div className="card profile-card m-0">
            <div className="row p-2">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="profile2">
                  <img
                    src={getPhotoUrl()}
                    className="img-fluid profile-img"
                    alt={firstName}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 ">
                <div className="row">
                  <h1 className="p-0">
                    <span className="fs-2">I'm</span> {firstName} {lastName}
                    <button
                      className="btn btn-primary ms-3 btn-addtech"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal">
                      Add Technology
                    </button>
                    <button
                      className="btn btn-primary ms-3 btn-profile"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2">
                      Edit Profile
                    </button>
                  </h1>
                  <hr />
                  <div className="col-6 p-0">
                    <div className="info">
                      <p>First Name: </p>
                      <p>Last Name: </p>
                      <p>Email ID: </p>
                      <p>Password: </p>
                      <p>Company Name :</p>
                      <p>Technology Name :</p>
                      <p>Mobile Number :</p>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className="information">
                      <p>{firstName}</p>
                      <p>{lastName}</p>
                      <p>{email}</p>
                      <p>{password || "N/A"}</p>
                      <p>{companyName || "N/A"}</p>
                      <p>
                        {technologyName.length > 0
                          ? technologyName.map((t) => t.tech_name).join(", ")
                          : "N/A"}
                      </p>
                      <p>{mobile || "N/A"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModal" tabIndex="-1">
            <div className="modal-dialog">
              <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Add technology</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
                <form onSubmit={handleTechnologySubmit}>
                  <div className="modal-body">
                    <select
                      value={addTechnology}
                      onChange={(e) => setAddTechnology(e.target.value)}
                      className="form-select"
                      required>
                      <option value="">Select Technology</option>
                      {technologies.map((tech) => (
                        <option key={tech.id} value={tech.id}>
                          {tech.tech_name}
                        </option>
                      ))}
                    </select>
                    <div className="mt-3 text-end">
                      <button type="submit" className="btn btn-primary btn-common">
                        Add Technology
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="modal fade" id="exampleModal2" tabIndex="-1">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">Edit Profile</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="modal-body">
                    <div className="mb-3">
                      <label htmlFor="firstName" className="form-label">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="lastName" className="form-label">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="companyName" className="form-label">Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="mobile" className="form-label">Mobile Number</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="mobile"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="photo" className="form-label">Profile Photo</label>
                      <input
                        type="file"
                        className="form-control"
                        id="photo"
                        onChange={(e) => setPhoto(e.target.files[0])}
                      />
                    </div>
                  </div>
                  <div className="text-end btn-save">
                    <button type="submit" className="btn btn-common" data-bs-dismiss="modal">Save Changes</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
