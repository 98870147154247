import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { showError, showMessage } from "./AlertMessage";
import { ToastContainer } from "react-toastify";
import GetBase from "./GetBase";

export default function SignUp() {
  const [technologies, setTechnologies] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    companyName: "",
    photo: null,
    selectedTechnologies: [],
    mobile: "",
  });

  // Fetch technologies on component mount
  useEffect(() => {
    axios
      .get(GetBase() + "technology")
      .then((response) => setTechnologies(response.data))
      .catch((error) => console.error("Error fetching technologies:", error));
  }, []);

  // Handle form field changes
  const handleChange = (e) => {
    const { id, value, files } = e.target;
    if (id === "photo") {
      setFormData((prev) => ({ ...prev, photo: files[0] }));
    } else if (id === "technology") {
      const selectedOptions = Array.from(e.target.selectedOptions);
      const selectedTechIds = selectedOptions.map((option) =>
        parseInt(option.value)
      );
      const selectedTechPrices = selectedTechIds.map(
        (techId) => technologies.find((tech) => tech.id === techId)?.price || 0
      );
      const totalPrice = selectedTechPrices.reduce(
        (sum, price) => sum + price,
        0
      );
      setSelectedPrice(totalPrice);
      setFormData((prev) => ({
        ...prev,
        selectedTechnologies: selectedTechIds,
      }));
    } else {
      setFormData((prev) => ({ ...prev, [id]: value }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, password, companyName, mobile, photo } =
      formData;
    const data = new FormData();
    if (
      !firstName ||
      !lastName ||
      !email ||
      !password ||
      !companyName ||
      !mobile ||
      !photo
    ) {
      showError("All fields are required");
      return;
    }

    try {
      data.append("first_name", firstName);
      data.append("last_name", lastName);
      data.append("email", email);
      data.append("password", password);
      data.append("company_name", companyName);
      data.append("mobile", mobile);
      data.append("photo", photo);
      const response = await axios.post(GetBase() + "signup", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data.id) {
        showMessage("Signup successful!");
        initiatePayment(response.data.id);
      } else {
        console.error("User ID is undefined in response:");
      }
    } catch (error) {
      console.error("Error signing up:", error.response?.data || error.message);
      showError("Signup failed. Please try again.");
    }
  };
  const initiatePayment = (userId) => {
    const customerName = `${formData.firstName} ${formData.lastName}`;
    const customerEmail = formData.email;
    const customerPhone = formData.mobile;

    axios
      .post(GetBase() + "create-payment", {
        order_amount: Number(selectedPrice),
        customer_name: customerName,
        customer_email: customerEmail,
        customer_phone: customerPhone,
        return_url: `https://synoventum.4born.in/`,
        // return_url: `https://localhost:3000/`,
      })
      .then((response) => {
        if (response.data && response.data.payment_link) {
          const orderId = response.data.order_id;

          if (!orderId) throw new Error("Order ID is missing in the response.");
          localStorage.setItem("orderId", orderId);
          localStorage.setItem("userId", userId);
          localStorage.setItem("customerName", customerName);
          localStorage.setItem("customerEmail", customerEmail);
          localStorage.setItem("customerPhone", customerPhone);
          localStorage.setItem(
            "selectedTechnologies",
            JSON.stringify(formData.selectedTechnologies)
          );

          window.location.href = response.data.payment_link;
        } else {
          console.error("Error: Payment link not found");
          showError("Payment link not found. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error initiating payment:", error);
        showError("Payment initiation failed. Please try again.");
      });
  };

  return (
    <div className="container-fluid sign-box">
      <ToastContainer />
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-12">
          <div className="card sign-card m-0">
            <p className="mb-4">
              <Link to="/" className="btn btn-back p-0">
                <i className="fa-solid fa-angle-left"></i> Back
              </Link>
            </p>
            <h1 className="fs-2">Create your Account</h1>
            <p>
              Welcome to <span className="text-col">Synoventum Partners!</span>{" "}
              Please enter your details.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 mb-3">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="Enter your first name"
                  />
                </div>
                <div className="col-12 mb-3">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Enter your last name"
                  />
                </div>
                <div className="col-12 mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                  />
                </div>
                <div className="col-12 mb-3">
                  <label htmlFor="mobile" className="form-label">
                    Contact Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    placeholder="Enter your contact number"
                  />
                </div>
                <div className="col-12 mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Enter your password"
                  />
                </div>
                <div className="col-12 mb-3">
                  <label htmlFor="companyName" className="form-label">
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    placeholder="Enter your company name"
                  />
                </div>
                <div className="col-12 mb-3">
                  <label htmlFor="photo" className="form-label">
                    Photo
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="photo"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 mb-3">
                  <label htmlFor="technology" className="form-label">
                    Select Technologies
                  </label>
                  <select
                    className="form-select"
                    id="technology"
                    onChange={handleChange}
                  >
                    {technologies.map((tech) => (
                      <option key={tech.id} value={tech.id}>
                        {tech.tech_name} - ₹{tech.price}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <button type="submit" className="btn btn-primary w-100 btn-common">
                Create Account
              </button>

              <div className="col-12 text-center mt-3">
              <p>
                Already have an account? <Link to="/">Sign in</Link>
              </p>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
