import React, { useState, useEffect } from "react";
import { showError, showMessage } from "./AlertMessage";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { Link } from "react-router-dom";
import GetBase from "./GetBase";
import axios from "axios";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const orderId = localStorage.getItem("orderId");
    const userId = localStorage.getItem("userId");
    const selectedTechnologies = JSON.parse(
      localStorage.getItem("selectedTechnologies")
    );
    const customerName = localStorage.getItem("customerName");
    const customerEmail = localStorage.getItem("customerEmail");
    const customerPhone = localStorage.getItem("customerPhone");

    if (orderId && userId && selectedTechnologies && !isVerifying) {
      setIsVerifying(true);
      verifyOrder(
        orderId,
        userId,
        selectedTechnologies,
        customerName,
        customerEmail,
        customerPhone
      ).finally(() => setIsVerifying(false));
    } else {
      console.warn("Order or user data missing from localStorage.");
    }
  }, []);

  const verifyOrder = async (
    orderId,
    userId,
    technologies,
    customerName,
    customerEmail,
    customerPhone
  ) => {
    try {
      const OrderResponse = await axios.post(GetBase() + `verify/${orderId}`, {
        order_details: {
          customer_name: customerName,
          customer_email: customerEmail,
          customer_phone: customerPhone,
        },
      });

      const OrderData = OrderResponse.data.data;
      console.log("Order Data:", OrderData);
      if (OrderData.order_status === "PAID") {
        console.log("Order successful!");
        await addTechnologiesForUser(userId, technologies);
        showMessage("Order verified and technologies added!");
        localStorage.removeItem("orderId");
        localStorage.removeItem("selectedTechnologies");
        localStorage.removeItem("customerName");
        localStorage.removeItem("customerEmail");
        localStorage.removeItem("customerPhone");
      }
    } catch (error) {
      console.error("Error verifying Order:", error);
    }
  };

  const addTechnologiesForUser = async (userId, technologies) => {
    console.log("Adding technologies for user:", userId);
    try {
      const promises = technologies.map((techId) =>
        axios.post(GetBase() + `user-technology/${userId}`, {
          technology_id: techId,
        })
      );
      await Promise.all(promises);
      console.log("Technologies added successfully!");
    } catch (error) {
      console.error("Error adding technologies:", error);
    }
  };

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(GetBase() + "signin", {
        email,
        password,
      });
      const userId = response.data.user.id;

      if (userId) {
        localStorage.setItem("userId", userId);
        try {
          const techResponse = await axios.get(
            GetBase() + `user-technology/${userId}`
          );
          if (techResponse.data.length === 0) {
            showError("You must add technologies before signing in.");
            navigate(`/add-tech`);
          } else {
            showMessage("Sign-In successful");
            setTimeout(() => {
              navigate(`/dashboard`);
            }, 1000);
          }
        } catch (techError) {
          showError("No technologies found");
          setTimeout(() => {
            navigate(`/add-tech`);
          }, 1000);
        }
      } else {
        console.error("Authentication failed. No user ID found.");
      }
    } catch (error) {
      setError(
        error.response
          ? error.response.data.message
          : "No response from server."
      );
      showError("Authentication failed.");
    }
  };

  const responseGoogle = async (response) => {
    console.log("Google Sign-In Response:", response);
    const { credential } = response;

    try {
      const googleSignInResponse = await axios.post(
        GetBase() + "google-signin",
        { credential }
      );
      const { user } = googleSignInResponse.data;
      localStorage.setItem("userId", user.id);

      if (user && user.id) {
        const userInfoResponse = await axios.get(
          GetBase() + `user-info/${user.id}`
        );
        const mobile = userInfoResponse.data.mobile;

        const techResponse = await axios.get(
          GetBase() + `user-technology/${user.id}`
        );
        const technologies = techResponse.data;

        if (mobile && technologies.length > 0) {
          showMessage("Welcome back!");
          navigate("/dashboard");
        } else {
          showMessage("Complete your profile.");
          navigate("/profile");
        }
      } else {
        console.error("User ID not found.");
        showError("User ID missing. Please sign in again.");
        navigate("/profile");
      }
    } catch (error) {
      console.error("Google Sign-In Error:", error);
      showError("Complete your profile.");
      setTimeout(() => {
        navigate("/profile");
      }, 1000);
    }
  };

  return (
    <div className="container-fluid sign-box">
      <ToastContainer />
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-12">
          <div className="card sign-card m-0">
            <h1>Sign In</h1>
            <p>
              Welcome to <span className="text-col">Synoventum Partners!</span>{" "}
              Please enter your details.
            </p>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email"
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Enter your password"
                  required
                />
              </div>

              {error && <p className="text-danger">{error}</p>}
              <button type="submit" className="btn btn-login w-100 mb-3">
                Sign In
              </button>
            </form>

            <div className="col-12 mb-3 text-center d-flex align-items-center">
              <span className="flex-grow-1 bor"></span>
              <span className="mx-2 or-text">or</span>
              <span className="flex-grow-1 bor"></span>
            </div>

            <div className="col-12 mb-3 w-100 d-flex justify-content-center align-items-center">
              <GoogleLogin
                onSuccess={responseGoogle}
                onFailure={() =>
                  setError("Google Sign-In failed. Please try again.")
                }
              />
            </div>

            <div className="col-12 text-center">
              <p>
                Don't have an account? <Link to="/signup">Sign up</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
