import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Profile from "./Profile";
import Dashboard from "./Dashboard";
import AddTech from "./AddTech";
import PaymentResult from "./PaymentResult";
import PaymentResult2 from "./PaymentResult2";
import { GoogleOAuthProvider } from "@react-oauth/google";

function ROUTES() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/add-tech" element={<AddTech />} />
        <Route path="/payment-result" element={<PaymentResult />} />
        <Route path="/payment-result2" element={<PaymentResult2 />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <GoogleOAuthProvider clientId="930041215692-tpocept9o73qslejaucjm0cn1ja9sgcr.apps.googleusercontent.com">
      <ROUTES />
    </GoogleOAuthProvider>
);
