import React, { useState, useEffect } from "react";
import { showError, showMessage } from "./AlertMessage";
import { useNavigate } from "react-router-dom";
import GetBase from "./GetBase";
import axios from "axios";

export default function PaymentResult2() {
  const [isVerifying, setIsVerifying] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const orderId = localStorage.getItem("orderId3");
    const userId = localStorage.getItem("userId");
    const selectedTechnologies = JSON.parse(
      localStorage.getItem("selectedTechnologies")
    );
    const customerName = localStorage.getItem("customerName");
    const customerEmail = localStorage.getItem("customerEmail");
    const customerPhone = localStorage.getItem("customerPhone");

    if (orderId && userId && selectedTechnologies && !isVerifying) {
      setIsVerifying(true);
      verifyOrder(
        orderId,
        userId,
        selectedTechnologies,
        customerName,
        customerEmail,
        customerPhone
      ).finally(() => setIsVerifying(false));
    } else {
      console.warn("Order or user data missing from localStorage.");
    }
  }, []);

  const verifyOrder = async (
    orderId,
    userId,
    technologies,
    customerName,
    customerEmail,
    customerPhone
  ) => {
    try {
      const OrderResponse = await axios.post(GetBase() + `verify/${orderId}`, {
        order_details: {
          customer_name: customerName,
          customer_email: customerEmail,
          customer_phone: customerPhone,
        },
      });

      const OrderData = OrderResponse.data.data;
      console.log("Order Data:", OrderData);
      if (OrderData.order_status === "PAID") {
        console.log("Order successful!");
        await addTechnologiesForUser(userId, technologies);
        showMessage("Order verified and technologies added!");
        localStorage.removeItem("orderId3");
        localStorage.removeItem("selectedTechnologies");
        localStorage.removeItem("customerName");
        localStorage.removeItem("customerEmail");
        localStorage.removeItem("customerPhone");
        navigate("/dashboard");
      } else {
        navigate("/profile");
        showError("Order verification failed.");
      }
    } catch (error) {
      navigate("/profile");
      console.error("Error verifying Order:", error);
    }
  };

  const addTechnologiesForUser = async (userId, technologies) => {
    console.log("Adding technologies for user:", userId, technologies);
    axios
      .post(GetBase() + `user-technology/${userId}`, {
        technology_id: technologies,
      })
      .then((response) => {
        console.log("Technologies added successfully!");
      })
      .catch((error) => {
        console.error("Error adding technologies:", error);
      });
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 process">
            <h1>Processing...</h1>
          </div>
        </div>
      </div>
    </>
  );
}
