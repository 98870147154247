import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { addDays, format, parseISO } from "date-fns";
import axios from "axios";
import GetBase from "./GetBase";

export default function Dashboard() {
  const [activeLink, setActiveLink] = useState("All");
  const [showDate, setShowDate] = useState(false);
  const [finalState, setFinalState] = useState(null);
  const [technologyData, setTechnologyData] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [tempState, setTempState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const navigate = useNavigate();
  const id = localStorage.getItem("userId");

  useEffect(() => {
    if (!id) {
      navigate("/", { replace: true });
    }
    if (id) {
      axios
        .get(GetBase() + `user-info/${id}`)
        .then((userInfoResponse) => {
          const mobile = userInfoResponse.data.mobile;
          axios
            .get(GetBase() + `user-technology/${id}`)
            .then((techResponse) => {
              const technologies = techResponse.data;
              if (mobile && technologies.length > 0) {
                navigate("/dashboard");
              } else {
                alert("Complete your profile to access the dashboard.");
                navigate("/profile");
              }
            })
            .catch((error) => {
              console.error("Error fetching technology data:", error);
              navigate("/profile");
            });
        })
        .catch((error) => {
          console.error("Error fetching user info:", error);
          navigate("/profile");
        });
    } else {
      navigate("/", { replace: true });
    }
  }, [id, navigate]);

  useEffect(() => {
    if (id) {
      axios
        .get(GetBase() + `user-info/${id}`)
        .then((response) => {
          setPhoto(response.data.photo);
        })
        .catch((error) => {
          console.log("There was an error fetching the user data!");
        });
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      axios
        .get(GetBase() + `user-technology/${id}`)
        .then((response) => {
          setTechnologyData(response.data);
        })
        .catch((error) => {
          console.log("There was an error fetching the technology data!");
        });
    }
  }, [id]);

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  const showDateRange = () => {
    setShowDate(!showDate);
  };

  const handleApplyClick = () => {
    setFinalState(tempState);
    setShowDate(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("userId");
    navigate("/", { replace: true }); 
  };

  const getPhotoUrl = () => {
    if (photo && photo.startsWith("https://")) {
      return photo;
    }
    return photo ? GetBase() + `uploads/${photo}` : "../assets/img/avatar.png";
  };

  const formatDate = (dateString) => format(parseISO(dateString), "dd/MM/yyyy");

  const filteredTechnologyData = technologyData.filter((item) => {
    const matchesLink = activeLink === "All" || item.tech_name === activeLink;
    const matchesDate =
      !finalState ||
      (parseISO(item.created_at) >= finalState[0].startDate && parseISO(item.created_at) <= finalState[0].endDate);
    return matchesLink && matchesDate;
  });

  return (
    <>
      <header>
        <div className="heading">
          <div className="hpart-1">
            <div className="home-icon">
              <img src="../assets/img/home.png" alt="..." />
            </div>
            <div>
              <div className="home-text">Overview</div>
              <div className="home-text">Home Page</div>
            </div>
          </div>
          <div className="hpart-2">
            <div className="date">
              {finalState ? (
                <div className="main-date cus" onClick={showDateRange}>
                  <div>
                    {format(finalState[0].startDate, "dd/MM/yyyy")} -{" "}
                    {format(finalState[0].endDate, "dd/MM/yyyy")}
                  </div>
                </div>
              ) : (
                <button onClick={showDateRange} className="btn main-date">
                  <i className="fa-regular fa-calendar"></i>&nbsp; Select date
                </button>
              )}
              {showDate && (
                <div className="date-range-picker">
                  <DateRangePicker
                    onChange={(item) => setTempState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={tempState}
                    direction="horizontal"/>
                  <div className="date-picker-controls p-2 d-flex justify-content-end align-items-center">
                    <button
                      className="btn btn-danger btn-cancel me-2"
                      onClick={() => setShowDate(false)}>
                      Cancel
                    </button>
                    <button
                      className="btn btn-success"
                      onClick={handleApplyClick}>
                      Apply
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="profile">
              <ul className="nav nav-tabs">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    to="#"
                    role="button"
                    aria-expanded="false"
                  >
                    {id ? (
                      <img src={getPhotoUrl()} alt="Profile" />
                    ) : (
                      <div>Error: Profile ID is missing!</div>
                    )}
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item m-0" to={`/profile`}>
                        <i className="fa-solid fa-user"></i>
                        <span className="ps-3">Profile</span>
                      </Link>
                    </li>
                    <li>
                      <button
                        className="dropdown-item m-0"
                        onClick={handleLogout}>
                        <i className="fa-solid fa-lock input-icon"></i>
                        <span className="ps-3">Log Out</span>
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Navigation Section */}
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid sm-box p-0">
            <Link className="navbar-brand" to={`/dashboard`}>
              <img
                src="../assets/img/logo.png"
                className="img-fluid img-logo"
                alt="logo"/>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
            >
              <i className="fa-solid fa-bars" style={{ color: "#4c88ef" }}></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className={`nav-link ${ activeLink === "All" ? "active" : "" }`}
                    to="#"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    onClick={() => handleLinkClick("All")}>
                    <i className="fa-solid fa-list"></i> All
                  </Link>
                </li>
                {technologyData.map((item) => (
                  <li className="nav-item" key={`nav-${item.id}`}>
                    <Link
                      className={`nav-link ${ activeLink === `${item.tech_name}` ? "active" : "" }`}
                      to="#"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarNav"
                      onClick={() => handleLinkClick(`${item.tech_name}`)}>
                      <i className="fa-solid fa-list"></i> {item.tech_name}
                    </Link>
                  </li>
                ))}
                <li className="nav-item hide-link">
                  <Link className="nav-link" to={`/profile`} >
                    <i className="fa-solid fa-user"></i> Profile
                  </Link>
                </li>
                <li className="nav-item hide-link">
                  <Link className="nav-link" to="/">
                    <i className="fa-solid fa-lock input-icon"></i> Log Out
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main className="details">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-end">
            <div className="date date-sm">
            {finalState ? (
              <div className="main-date cus" onClick={showDateRange}>
                <div>
                  {format(finalState[0].startDate, "dd/MM/yyyy")} -{" "}
                  {format(finalState[0].endDate, "dd/MM/yyyy")}
                </div>
              </div>
            ) : (
              <button onClick={showDateRange} className="btn main-date">
                <i className="fa-regular fa-calendar"></i>&nbsp; Select date
              </button>
            )}
            {showDate && (
              <div className="date-range-picker">
                <DateRangePicker
                  onChange={(item) => setTempState([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={tempState}
                  direction="horizontal"/>
                <div className="date-picker-controls p-2 d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-danger btn-cancel me-2"
                    onClick={() => setShowDate(false)}>
                    Cancel
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={handleApplyClick}>
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="hide-date">
            {finalState && finalState.length > 0 && (
              <div className="main-date">
                <div>
                  {format(finalState[0].startDate, "dd/MM/yyyy")} -{" "}
                  {format(finalState[0].endDate, "dd/MM/yyyy")}
                </div>
              </div>
            )}
          </div>
            </div>
          </div>
          {filteredTechnologyData.map((item) => (
            <div className="row" key={`row-${item.id}`}>
              <div className="col-12 d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h5>{item.tech_name}</h5>
                </div>
              </div>
              <div className="col-12 mb-3" key={`card-${item.id}`}>
                <div
                  className="card main-card"
                  data-bs-toggle="modal"
                  data-bs-target={`#${item.tech_name}`}>
                  <div className="card-body d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="card-title">Features</h5>
                      <div className="card-text" key={item.id}>
                        <p className="m-0 truncated-text">{item.content}</p>
                      </div>
                    </div>
                    <div className="content-date">
                      <p>{formatDate(item.created_at)}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {technologyData.map((item) => (
                  <div
                    className="modal fade"
                    id={`${item.tech_name}`}
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    key={`modal-${item.id}`}>
                    <div className="modal-dialog modal-dialog-scrollable modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="exampleModalLabel">
                            Features
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="card mb-3">
                            <div className="card-body" key={item.id}>
                              <h5 className="card-title">{item.tech_name}</h5>
                              <div className="card-text">
                                <p className="m-0">{item.content}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </main>
    </>
  );
}
