import React, { useState, useEffect } from "react";
import axios from "axios";
import { showError } from "./AlertMessage";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import GetBase from "./GetBase";

export default function AddTech() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [technologies, setTechnologies] = useState([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const [addTechnology, setAddTechnology] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (!userId) {
      navigate("/", { replace: true });
    }
  }, [userId, navigate]);

  useEffect(() => {
    if (!dataFetched) {
      const fetchData = async () => {
        try {
          const userResponse = await axios.get(GetBase() + `user-info/${userId}`);
          const { first_name, last_name, email, mobile } = userResponse.data;
          setFirstName(first_name);
          setLastName(last_name);
          setEmail(email);
          setMobile(mobile);

          const techResponse = await axios.get(GetBase() + "technology");
          setTechnologies(techResponse.data);

          setDataFetched(true);
        } catch (error) {
          showError("Error fetching data.");
        }
      };
      fetchData();
    }
  }, [dataFetched, userId]);

  const handleTechnologySubmit = async (e) => {
    e.preventDefault();

    if (!addTechnology) {
      showError("Please select a technology.");
      return;
    }

    try {
      const techResponse = await axios.get(GetBase() + `technology/${addTechnology}`);
      const techPrice = techResponse.data.price;

      const paymentResponse = await axios.post(GetBase() + "create-payment", {
        order_amount: techPrice,
        customer_name: `${firstName} ${lastName}`,
        customer_email: email,
        customer_phone: mobile,
        return_url: 'https://synoventum.4born.in/payment-result',
        // return_url: 'https://localhost:3000/payment-result',
      });

      const orderId = paymentResponse.data.order_id;

      // Store relevant data in local storage
      localStorage.setItem("orderId2", orderId);
      localStorage.setItem("userId", userId);
      localStorage.setItem("customerName", firstName);
      localStorage.setItem("customerEmail", email);
      localStorage.setItem("customerPhone", mobile);

      // Update selected technologies in local storage
      const updatedTechnologies = [...selectedTechnologies, addTechnology];
      setSelectedTechnologies(updatedTechnologies); // Update state
      localStorage.setItem("selectedTechnologies", JSON.stringify(updatedTechnologies));

      if (paymentResponse.data.payment_link) {
        window.location.href = paymentResponse.data.payment_link;
      } else {
        showError("Error creating payment.");
      }
    } catch (error) {
      showError("Error during payment: " + error.message);
    }
  };

  return (
    <div className="container profile-box">
      <ToastContainer />
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-12">
          <div className="card sign-card m-0">
            <p className="mb-4">
              <Link to="/" className="btn btn-back p-0">
                <i className="fa-solid fa-angle-left"></i> Back
              </Link>
            </p>
            <h1 className="fs-2">Add Technology</h1>
            <p>Please add technology to continue.</p>
            <form onSubmit={handleTechnologySubmit}>
              <div className="modal-body">
                <label htmlFor="addtech" className="form-label">
                  Add technology
                </label>
                <select
                  value={addTechnology}
                  onChange={(e) => setAddTechnology(e.target.value)}
                  className="form-select"
                  id="addtech"
                  required
                >
                  <option value="">Select Technology</option>
                  {technologies.map((tech) => (
                    <option key={tech.id} value={tech.id}>
                      {tech.tech_name}
                    </option>
                  ))}
                </select>
                <div className="mt-3 text-end">
                  <button type="submit" className="btn btn-primary btn-common">
                    Add Technology
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
